import * as S from '../../../pages/Register/styles';
import { MdClose } from 'react-icons/md';
import { Typography } from '../Typography';
import { Button } from '../Button';
import Modal from '../Modal';
import React from 'react';
import styled from 'styled-components';

interface Props {
	errorMessage : {
		message: string;
		title: string;
	} | null;
	resetMessage: () => void
}

export default function ErrorModal({errorMessage, resetMessage} : Props ){
	return (
		<Modal
			headerTitle={errorMessage?.title}
			isOpen={errorMessage !== null}
			onRequestClose={resetMessage}
		>
			<ErrorBody>
				<S.IconContainer>
					<MdClose size={30} color={"#FFFFFF"} />
				</S.IconContainer>
				<Typography>
					{errorMessage?.message}
				</Typography>
				<Button
					type={'button'}
					roundness={"lg"}
					onClick={resetMessage}
					intent={'secondary'}>
					Fechar
				</Button>
			</ErrorBody>
		</Modal>
	)
}

export const ErrorBody = styled.div`
	width: 45.5rem;
	display: flex;
	padding: 3.2rem;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 2.4rem;
	@media (max-width: 1024px) {
		width: 100%;
	}
`