import axios from 'axios';

const viaCEPAPI = axios.create({
	baseURL: `https://viacep.com.br/ws/`,
});

interface AddressByCEPResponse {
	cep: string;
	logradouro: string;
	complemento: string;
	bairro: string;
	localidade: string;
	uf: string;
}

export async function getAddressByCEP(cep: string) {
	const { data } = await viaCEPAPI.get<AddressByCEPResponse>(`${cep}/json`);
	return data;
}
