import { Form } from '../../styles';
import { InputField } from '../../../../components/ui/Form/InputField';
import { SelectField } from '../../../../components/ui/Form/SelectField';
import { z } from 'zod';
import { useFormContext } from 'react-hook-form';
import { phoneMaskNoInternationalCode } from '../../../../utils/masks';
import styled from 'styled-components';

export const ContactFormSchema = z.object({
	contact: z.object({
		full_name: z.string().min(1, 'O nome completo é obrigatório'),
		email: z.string().email('E-mail inválido').min(1, 'O e-mail é obrigatório'),
		phone: z.string().min(10, 'O telefone é obrigatório'),
		position_in_the_company: z
			.string()
			.min(1, 'Sua função na empresa é obrigatório.'),
	}),
});

type ContactFormErrors = z.infer<typeof ContactFormSchema>;

const PrimaryFunctions = [
	{ label: 'Sócio/Fundador', value: 'socio_fundador' },
	{ label: 'Procurador responsável', value: 'procurador_responsavel' },
	{ label: 'Responsável financeiro', value: 'responsavel_financeiro' },
	{ label: 'Time financeiro', value: 'time_financeiro' },
	{ label: 'Gestor de área', value: 'gestor_area' },
	{ label: 'Colaborador', value: 'colaborador' },
	{ label: 'Outro', value: 'outro' },
];

const ContactForm = () => {
	const {
		register,
		formState: { errors },
	} = useFormContext<ContactFormErrors>();

	return (
		<Form>
			<InputField
				required
				label='Seu nome completo'
				name='contact.full_name'
				register={register}
				errorMessage={
					errors.contact?.full_name &&
					typeof errors.contact.full_name.message === 'string'
						? errors.contact.full_name.message
						: undefined
				}
			/>
			<InputField
				required
				name='contact.email'
				label='Seu e-mail corporativo'
				register={register}
				placeholder='email@example.com'
				errorMessage={
					errors.contact?.email &&
					typeof errors.contact?.email.message === 'string'
						? errors.contact?.email.message
						: undefined
				}
			/>

			<PhoneCodeWrapper>
				<div>
					<span>+55</span>
				</div>
				<InputField
					required
					register={register}
					onChange={e => {
						const {value} = e.target
						e.target.value = phoneMaskNoInternationalCode(value)
					}}
					name='contact.phone'
					label='Seu telefone'
					placeholder='(00) 0000-0000'
					errorMessage={errors.contact?.phone?.message}
				/>
			</PhoneCodeWrapper>



			<SelectField
				required
				label='Sua principal função na empresa'
				name='contact.position_in_the_company'
				register={register}
				errorMessage={
					errors.contact?.position_in_the_company &&
					typeof errors.contact?.position_in_the_company.message === 'string'
						? errors.contact?.position_in_the_company.message
						: undefined
				}
			>
				{PrimaryFunctions.map((option) => (
					<option key={option.value} value={option.value}>
						{option.label}
					</option>
				))}
			</SelectField>
		</Form>
	);
};

const PhoneCodeWrapper = styled.div`
	position: relative;
	display: flex;
	align-items: flex-end;
	& > div {
			display: flex;
			align-items: center;
			background-color: #f0f3f8;
			font-size: 1.5rem;
			position: absolute;
			left: 1.5rem;
			top: 36px;

			& > span {
					color: var(--dark-gray);
					font-family: 'Poppins', sans-serif;
					font-size: 1.4rem;
			}
      @media (max-width: 1024px) {
          top: 33px;
      }
	}
	& > fieldset {
			 & > input {
					 padding-left: 5rem;
			 }
	}
	@media (max-width: 1024px) {
			width: 100%;
	}
`;

export default ContactForm;
