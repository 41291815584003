export function getUtmParams(searchParams: URLSearchParams) {
	const result: Record<string, string | null> = {
		utm_id: searchParams.get('utm_id'),
		utm_source: searchParams.get('utm_source'),
		utm_medium: searchParams.get('utm_medium'),
		utm_campaign: searchParams.get('utm_campaign'),
		utm_term: searchParams.get('utm_term'),
		utm_content: searchParams.get('utm_content'),
	};

	if (Object.keys(result).length == 0) return "";

	const cleaned = Object.keys(result).reduce((acc, key) => {
		const value = result[key];
		if (value !== null) {
			acc[key] = value;
		}
		return acc;
	}, {} as Record<string, string>);

	return cleaned
}