import { useFormContext } from 'react-hook-form';
import * as S from '../../styles';
import { z } from 'zod';
import { InputField } from '../../../../components/ui/Form/InputField';
import { useGetAddressByCEP } from '../../../../hooks/useGetAddressByCEP';
import { SelectField } from '../../../../components/ui/Form/SelectField';
import { UF_List } from '../../../../utils/CheckUF';
import {
	cepMask,
	maskAlphaNumeric,
	parseMaskedPhoneToRaw,
	phoneMaskNoInternationalCode,
} from '../../../../utils/masks';

export const CompanyFormSchema = z.object({
	website: z.string().min(1, 'O website é obrigatório'),
	phone_number: z.string().min(1, 'O número de telefone é obrigatório').refine(
		(number) => parseMaskedPhoneToRaw(number).length >= 11, "Digite um número de telefone válido"
	),
	email: z.string().min(1, 'O e-mail é obrigatório').email('Digite um e-mail válido'),
	address: z.object({
		postal_code: z.string().min(8, 'Digite um CEP válido'),
		neighborhood: z.string().min(1, 'O bairro é obrigatório'),
		street: z.string().min(1, 'O endereço é obrigatório'),
		number: z.string().min(1, 'O número é obrigatório'),
		city: z.string().min(1, 'A cidade é obrigatória'),
		state: z.string().min(1, 'O estado é obrigatório'),
		complement: z.string().optional(),
		reference: z.string().optional(),
	}),
});

type ContactFormErrors = z.infer<typeof CompanyFormSchema>;

export default function CompanyForm() {
	const {
		register,
		setValue,
		formState: { errors },
	} = useFormContext<ContactFormErrors>();

	const { searchAddressByCEP, isSearchingCEP } = useGetAddressByCEP({
		onAddressFoundCallback: (address) => {
			setValue("address.street", address.address)
			setValue("address.neighborhood", address.district)
			setValue("address.number", address.number)
			setValue("address.city", address.city)
			setValue("address.state", address.uf)
		},
	});

	return (
		<S.FormContainer>
			<InputField
				style={{ width: '16.8rem' }}
				required
				label='CEP:'
				name='address.postal_code'
				placeholder='00000-000'
				inputMode={"numeric"}
				register={register}
				onChange={(e) => {
					const { value } = e.target;
					e.target.value = cepMask(value);
					if (value.length === 9 && !isSearchingCEP)
						searchAddressByCEP(value);
				}}
				onBlur={e => {
					const { value } = e.target;
					if (value.length === 9 && !isSearchingCEP) {
						searchAddressByCEP(value);
						setValue("address.postal_code", cepMask(value));
					}}
				}
				errorMessage={errors.address?.postal_code?.message}
			/>

			<InputField
				style={{ width: '36rem' }}
				label='Endereço:'
				name='address.street'
				maxLength={50}
				register={register}
				errorMessage={errors.address?.street?.message}
			/>
			<InputField
				style={{ width: '16.8rem' }}
				label='Número:'
				maxLength={10}
				placeholder='000'
				name='address.number'
				register={register}
				onChange={(e) => {
					const { value } = e.target;
					e.target.value = maskAlphaNumeric(value);
				}}
				errorMessage={errors.address?.number?.message}
			/>
			<InputField
				style={{ width: '36rem' }}
				label='Bairro:'
				maxLength={30}
				name='address.neighborhood'
				register={register}
				errorMessage={errors.address?.neighborhood?.message}
			/>
			<InputField
				style={{ width: '36rem' }}
				label='Cidade:'
				maxLength={30}
				name='address.city'
				register={register}
				errorMessage={errors.address?.city?.message}
			/>
			<SelectField
				style={{ width: '16.7rem' }}
				label='UF:'
				placeholder={"Selecionar UF"}
				name='address.state'
				register={register}
				errorMessage={errors.address?.state?.message}>
				{UF_List.map((uf) => (
					<option key={`address.state${uf}`} value={uf}>
						{uf}
					</option>
				))}
			</SelectField>
			<InputField
				style={{ width: '26.4rem' }}
				label='Complemento:'
				maxLength={30}
				name='address.complement'
				register={register}
				errorMessage={errors.address?.complement?.message}
			/>
			<InputField
				style={{ width: '26.4rem' }}
				label='Referência:'
				maxLength={30}
				name='address.reference'
				register={register}
				errorMessage={errors.address?.reference?.message}
			/>

			<S.PhoneCodeWrapper>
				<div>
					<span>+55</span>
				</div>
				<InputField
					required
					style={{ width: '26.4rem' }}
					label="Telefone:"
					placeholder="(00) 00000-0000"
					name="phone_number"
					register={register}
					onChange={(e) => {
						const { value } = e.target;
						e.target.value = phoneMaskNoInternationalCode(value);
					}}
					errorMessage={errors.phone_number?.message}
				/>
			</S.PhoneCodeWrapper>

			<InputField
				style={{ width: '26.4rem' }}
				label='Website:'
				name='website'
				register={register}
				errorMessage={errors.website?.message}
			/>

			<InputField
				style={{ width: '26.4rem' }}
				label='E-mail corporativo:'
				placeholder='example@email.com.br'
				name='email'
				register={register}
				errorMessage={errors.email?.message}
			/>
		</S.FormContainer>
	);
}



