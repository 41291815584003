import { createContext, ReactNode, useReducer } from 'react';
import { useRef } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import Modal from '../../components/Modal';
import {
	DialogModalActions,
	DialogModalReducer,
	initialState,
} from './reducer';
import * as S from './styles';

interface Props {
	children: ReactNode;
}

export interface DialogModalContextData {
	// MESSAGE TO DISPLAY AND ONSUBMIT CALLBACK
	openConfirmDialog(
		message: string,
		mainOptMessage: string,
		onSubmitMainOption: () => void,
		enableClose?: boolean
	): void;
	openOptionsDialog(
		message: string,
		mainOptMessage: string,
		onSubmitMainOption: () => void,
		secondaryOptMessage: string,
		onSubmitSecondaryOption: () => void,
		enableClose?: boolean
	): void;
}

export const DialogModalContext = createContext({} as DialogModalContextData);

// By having a separate parent component to the dialog modal component we can control
// z-index, then allowing the DialogModal to show on top of any other modal
export const OTHER_MODALS_CONTAINER_ID = 'lower-modal-container';
export const DIALOG_MODAL_CONTAINER_ID = 'upper-modal-container';

export const DialogModalProvider = ({ children }: Props) => {
	const submitMainButton = useRef<HTMLButtonElement>(null);
	const submitSecondButton = useRef<HTMLButtonElement>(null);
	const [state, dispatch] = useReducer(DialogModalReducer, initialState);

	// Open Confirmation Dialog with one option to click
	function openConfirmDialog(
		message: string,
		mainOptMessage: string, // message to main submit button
		onSubmitMainOption = () => {}, // default value is an empty function
		enableClose = true
	) {
		dispatch({
			type: DialogModalActions.SET_STATE,
			payload: {
				isOpen: true,
				message,
				mainOptMessage,
				enableClose,
			},
		});

		setTimeout(() => {
			// Without this timeout the submitbutton.current won't be mounted already
			submitMainButton.current?.addEventListener('click', () =>
				handleSubmit(onSubmitMainOption)
			);
			// submitSecondButton.current?.addEventListener('click', () => handleSubmit(onSubmitSecondOption))
		}, 20);

		return () => {
			submitMainButton.current?.removeEventListener('click', () =>
				handleSubmit(onSubmitMainOption)
			);
			// submitSecondButton.current?.removeEventListener('click', () => handleSubmit(onSubmitSecondOption))
		};
	}

	// Two options dialog
	function openOptionsDialog(
		message: string,
		mainOptMessage: string, // message to main submit button
		onSubmitMainOption = () => {}, // default value is an empty function
		secondOptMessage: string, // message to main submit button
		onSubmitSecondaryOption = () => {}, // default value is an empty function
		enableClose = false
	) {
		dispatch({
			type: DialogModalActions.SET_STATE,
			payload: {
				isOpen: true,
				message,
				mainOptMessage,
				secondOptMessage,
				enableClose,
			},
		});

		setTimeout(() => {
			// Without this timeout the submitbutton.current won't be mounted already
			submitMainButton.current?.addEventListener('click', () =>
				handleSubmit(onSubmitMainOption)
			);
			submitSecondButton.current?.addEventListener('click', () =>
				handleSubmit(onSubmitSecondaryOption)
			);
		}, 20);

		return () => {
			submitMainButton.current?.removeEventListener('click', () =>
				handleSubmit(onSubmitMainOption)
			);
			submitSecondButton.current?.removeEventListener('click', () =>
				handleSubmit(onSubmitSecondaryOption)
			);
		};
	}

	function handleSubmit(onSubmit: () => void) {
		onSubmit(); // Call the Submit function passed to the DialogModalContext when opened

		closeModal();
	}

	function closeModal() {
		dispatch({ type: DialogModalActions.RESET });
	}

	return (
		<DialogModalContext.Provider
			value={{ openConfirmDialog, openOptionsDialog }}
		>
			{children}

			<Modal
				isOpen={state.isOpen}
				onRequestClose={closeModal}
				enableClose={state.enableClose}
				parentId={DIALOG_MODAL_CONTAINER_ID}
			>
				<S.Container data-testid='DialogModal-modal-container'>
					<FaInfoCircle />
					<S.Message>{state.message}</S.Message>
					<S.OptionsContainer>
						<S.Option second={false} ref={submitMainButton}>
							{state.mainOptMessage}
						</S.Option>
						{state.secondOptMessage && (
							<S.Option second={true} ref={submitSecondButton}>
								{state.secondOptMessage}
							</S.Option>
						)}
					</S.OptionsContainer>
				</S.Container>
			</Modal>

			<div id={OTHER_MODALS_CONTAINER_ID} style={{ zIndex: 10 }}></div>
			{/* DIALOG_MODAL CONTAINER has a bigger zIndex, so it shows on top of any other MODAL */}
			<div id={DIALOG_MODAL_CONTAINER_ID} style={{ zIndex: 1000 }}></div>
		</DialogModalContext.Provider>
	);
};
