import axios from 'axios';

interface IMessage {
	title: string;
	message: string;
}

export function errorToMessage(error: any, defaultMessage: IMessage): IMessage {
	if (axios.isAxiosError(error)) {
		const errorData = error.response?.data;
		switch (errorData.errorEntityId) {
			case 4000:
				switch (errorData.messageId) {
					case 1:
						return {
							title: 'Registro não encontrado',
							message: 'O formulário que você está tentando acessar não existe.',
						};
					case 2:
						return {
							title: 'Registro já analizado',
							message: 'O formulário que você está tentando acessar já foi analizado.',
						};
					case 3:
						return {
							title: 'Cadastro não disponível',
							message: 'Entre em contato com o RH da sua empresa para mais informações.',
						};
					default:
						return defaultMessage;
				}
			default:
				return defaultMessage;
		}
	}
	return defaultMessage;
}

