import React, { useEffect, useState } from 'react';
import { Button } from '../../../components/ui/Button';
import Modal from '../../../components/ui/Modal';
import styled from 'styled-components';
import Hands from '../../../assets/HandsImage.png';
import Wallet from '../../../assets/WalletImage.png';
import { InputField } from '../../../components/ui/Form/InputField';
import { SelectField } from '../../../components/ui/Form/SelectField';
import FileField from '../../../components/ui/Form/FileField';
import { LegalPerson, NaturalPerson, Shareholder } from '../../../@types';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import StatusModal from './StatusModal';
import { cpf as cpfValidator, cnpj as cnpjValidator } from 'cpf-cnpj-validator';
import FileArrows from '../../../assets/file-arrow-up-down.svg';
import { IoMdTrash } from 'react-icons/io';
import { cnpjMask, cpfMask } from '../../../utils/masks';
import { MdErrorOutline } from 'react-icons/md';
import ErrorModal from '../../../components/ui/ErrorModal';

const PartnerProfileOptions = [
	{ label: 'Sócio', value: 'partner' },
	{ label: 'Procurador', value: 'proxyholder' },
	{ label: 'Representante legal', value: 'legal_representative' },
	{ label: 'Outro', value: 'other' },
];

interface NewPartnerModalProps {
	onSave: (partner: Shareholder) => boolean;
	editedPartner?: Shareholder | null;
	onEdit?: (editedPartner: Shareholder) => void;
	resetUpdatePartner: () => void;
}

const partnerSchema = z
	.object({
		type: z.enum(['Pessoa Física', 'Pessoa Jurídica']),
		name: z.string().optional(),
		document: z.string().optional(),
		birth_date: z.string().optional(),
		founding_date: z.string().optional(),
		social_contract_file: z.instanceof(File).nullable().optional(),
		user_type: z.string().optional(),
		legal_name: z.string().optional(),
	})
	.superRefine((data, ctx) => {
		if (data.type === 'Pessoa Física') {
			if (!data.name) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: 'O Nome é obrigatório.',
					path: ['name'],
				});
			}
			if (data.name && data.name.trim().split(" ").length < 2) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: 'É necessário informar o nome e sobrenome',
					path: ['name'],
				});
			}
			if (!data.document) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: 'O CPF é obrigatório.',
					path: ['document'],
				});
			}
			if (data.document && !cpfValidator.isValid(data.document)) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: 'CPF inválido.',
					path: ['document'],
				})
			}
			if (!data.birth_date) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: 'A data de nascimento é obrigatória.',
					path: ['birth_date'],
				});
			}
			if (!data.user_type) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: 'O tipo de sócio é obrigatório.',
					path: ['user_type'],
				});
			}
			if (!data.social_contract_file) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: 'O documento é obrigatório.',
					path: ['social_contract_file'],
				});
			}
			if (data.social_contract_file &&
				!['application/pdf'].includes(data.social_contract_file.type)) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: 'Por favor, escolha um dos formatos aceitos: JPG, PNG ou PDF.',
						path: ['social_contract_file'],
					});
			}
		} else if (data.type === 'Pessoa Jurídica') {
			if (!data.legal_name) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: 'A Razão Social é obrigatória.',
					path: ['legal_name'],
				});
			}
			if (!data.document) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: ' O CNPJ é obrigatório.',
					path: ['document'],
				});
			}
			if (data.document && !cnpjValidator.isValid(data.document)) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: 'CNPJ inválido.',
					path: ['document'],
				})
			}
			if (!data.social_contract_file) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: 'O documento é obrigatório.',
					path: ['social_contract_file'],
				});
			}
			if (data.social_contract_file && 'application/pdf' !== data.social_contract_file.type) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: 'Por favor, escolha um dos formatos aceitos: PDF',
					path: ['social_contract_file'],
				});
			}
			if (!data.user_type) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: 'O tipo de sócio é obrigatório.',
					path: ['user_type']
				})
			}
			if (!data.founding_date) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: 'A data de fundação é obrigatória.',
				})
			}
		}
		if (
			data.social_contract_file &&
			data.social_contract_file.size > 3 * 1024 * 1024
		) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: 'Por favor escolha um arquivo com no máximo 3MB.',
				path: ['social_contract_file'],
			});
		}
	});

type PartnerErrors = z.infer<typeof partnerSchema>;

const NewPartnerModal = ({
	onSave,
	editedPartner,
	onEdit,
	resetUpdatePartner,
}: NewPartnerModalProps) => {
	const [isOpen, setOpen] = useState(false);
	const [currentStep, setCurrentStep] = useState<number>(0);
	const [typePartner, setTypePartner] = useState<
		'Pessoa Física' | 'Pessoa Jurídica' | null
	>(null);
	const [openStatusModal, setOpenStatusModal] = useState(false);
	const [errorMessage, setErrorMessage] = useState<{title: string, message: string} | null>(null);

	const {
		register,
		control,
		setValue,
		reset,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm<PartnerErrors>({
		resolver: zodResolver(partnerSchema),
	});

	const file = watch('social_contract_file');

	useEffect(() => {
		if (editedPartner) {
			setOpen(true);
			setTypePartner(editedPartner.type);
			setCurrentStep(1);
			if (editedPartner.type === 'Pessoa Física') {
				const {
					name,
					birth_date,
					document,
					user_type,
					social_contract_file,
				} = editedPartner as NaturalPerson;
				setValue('name', name);
				setValue('birth_date', birth_date);
				setValue('document', document);
				setValue('user_type', user_type);
				setTypePartner('Pessoa Física');
				setValue("social_contract_file", social_contract_file);
				setValue("type", "Pessoa Física");
			} else if (editedPartner.type === 'Pessoa Jurídica') {
				const { legal_name, document, social_contract_file, user_type, foundation_date} =
					editedPartner as LegalPerson;
				setValue('legal_name', legal_name);
				setValue('document', document);
				setTypePartner('Pessoa Jurídica');
				setValue("social_contract_file", social_contract_file);
				setValue("type", "Pessoa Jurídica");
				setValue("founding_date", foundation_date)
				setValue("user_type", user_type);
			}
		}
	}, [editedPartner, setValue]);

	const clearFile = () => {
		setValue("social_contract_file", null)
	}

	const handleSave = (formData: any) => {
		const { name, legal_name } = formData;

		let partner: Shareholder;

		if (name) {
			partner = {
				type: 'Pessoa Física',
				name: formData.name,
				birth_date: formData.birth_date,
				document: formData.document,
				user_type: formData.user_type,
				social_contract_file: formData.social_contract_file
			} as NaturalPerson;
		} else if (legal_name) {
			partner = {
				type: 'Pessoa Jurídica',
				legal_name: formData.legal_name,
				document: formData.document,
				social_contract_file: formData.social_contract_file,
				foundation_date: formData.founding_date,
				user_type: formData.user_type
			} as LegalPerson;
		} else {
			return;
		}
		if (editedPartner) {
			if (onEdit) {
				const editedPartnerData = {
					...editedPartner,
					...partner,
				};
				onEdit(editedPartnerData);
				setOpen(false);
				setOpenStatusModal(true);
				setCurrentStep(0);
				reset();
				setTypePartner(null);
				resetUpdatePartner()
			}
		} else {
			const result = onSave(partner);
			if (result) {
				setOpen(false);
				setOpenStatusModal(true);
				setCurrentStep(0);
				reset();
				setTypePartner(null);
				resetUpdatePartner();
			} else {
				setErrorMessage({
					title: "Cadastro de novo sócio",
					message: "Este sócio já se encontra cadastrado"
				})
			}
		}

	};

	const PartnerTypeContent = () => {
		return (
				<div style={{ overflowX: 'auto', overflowY: 'hidden', display: 'flex', flexDirection: 'row', gap: '1.6rem' }}>
					<TypePartner>
						<div style={{ height: '17.1rem' }}>
							<img src={Hands} alt="" />
						</div>
						<PartnerInformation>
							<h1>Pessoa física</h1>
							<p>
								Para empresas cujo quadro societário é composto apenas por Pessoas
								Físicas.
							</p>
							<div></div>
							<Button
								style={{ width: '22.8rem', height: '4.8rem' }}
								intent="primary"
								roundness="lg"
								onClick={() => {
									setTypePartner('Pessoa Física');
									setValue('type', 'Pessoa Física');
									setCurrentStep(1);
								}}
							>
								Cadastro Sócio
							</Button>
						</PartnerInformation>
					</TypePartner>

					<TypePartner>
						<div style={{ height: '17.1rem' }}>
							<img src={Wallet} alt="" />
						</div>
						<PartnerInformation>
							<h1>Pessoa jurídica</h1>
							<p>
								Para empresas cujo quadro societário tem um ou mais sócios que são
								Pessoas Jurídicas.
							</p>
							<div></div>
							<Button
								style={{ width: '22.8rem', height: '4.8rem' }}
								intent="secondary"
								roundness="lg"
								onClick={() => {
									setTypePartner('Pessoa Jurídica');
									setValue('type', 'Pessoa Jurídica');
									setCurrentStep(1);
								}}
							>
								Cadastro Empresa
							</Button>
						</PartnerInformation>
					</TypePartner>
				</div>
		);
	};

	const user_type = watch('user_type');

	const FormContent = () => {
		const handleFileChange =
			(field: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
				const files = e.target.files;
				if (files && files.length > 0) {
					field.onChange(files[0]);
				}
			};

		return (
			<FormContainer>
				{typePartner === 'Pessoa Física' && (
					<>
						<InputField
							style={{ width: '39.2rem' }}
							label="Nome completo:"
							name="name"
							placeholder="Nome completo do sócio"
							register={register}
							errorMessage={errors.name?.message}
						/>

						<RowForm>
							<InputField
								required
								style={{ width: '21.2rem' }}
								label="CPF:"
								placeholder="000.000.000-00"
								name="document"
								register={register}
								onChange={(e) => {
									const { value } = e.target;
									e.target.value = cpfMask(value);
								}}
								errorMessage={errors.document?.message}
							/>
							<DateContainer>
								<InputField
									type="date"
									style={{ width: '16.6rem' }}
									label="Data de nascimento:"
									placeholder="00/00/0000"
									name="birth_date"
									register={register}
									errorMessage={errors.birth_date?.message}
								/>
							</DateContainer>
						</RowForm>

						<SelectField
							style={{ width: '39.2rem' }}
							label="Perfil do sócio:"
							name="user_type"
							register={register}
							errorMessage={errors.user_type?.message}
						>
							{PartnerProfileOptions.map((option) => (
								<option key={option.value} value={option.value}>
									{option.label}
								</option>
							))}
						</SelectField>
						{
							user_type && (
								<FileSectionContainer>
									{!file?.name && <Controller
										name="social_contract_file"
										control={control}
										render={({ field }) => (
											<FileField
												accept="application/pdf"
												label="Documento:"
												textContainer="Anexe RG ou CNH do sócio tamanho máximo de 3MB"
												style={{ width: '100%', height: '14.3rem' }}
												onChange={handleFileChange(field)}
												errorMessage={errors.social_contract_file?.message}
											/>
										)}
									/>}

									{file?.name && (
										<>
											<ContainerFile>
												<img src={FileArrows} alt="" />
												<FileName>{file?.name}</FileName>
												<ClearButton type={'button'} onClick={clearFile}>
													<IoMdTrash color="var(--primary-blue)" size={24} />
												</ClearButton>
											</ContainerFile>
											{
												errors.social_contract_file?.message && (
													<ErrorMessage>
														<MdErrorOutline /> {errors.social_contract_file?.message}
													</ErrorMessage>
												)
											}
										</>
									)}
								</FileSectionContainer>
							)
						}
					</>
				)}
				{typePartner === 'Pessoa Jurídica' && (
					<>
						<InputField
							style={{ width: '39.2rem' }}
							label="Razão social:"
							placeholder="Nome da empresa sócia"
							name="legal_name"
							register={register}
							errorMessage={errors.legal_name?.message}
						/>
						<InputField
							style={{ width: '39.2rem' }}
							required
							name="document"
							label="CNPJ:"
							placeholder="00.000.000/0000-00"
							register={register}
							onChange={e => {
								const { value } = e.target;
								e.target.value = cnpjMask(value);
							}}
							errorMessage={errors.document?.message}
						/>
						<RowForm>
							<SelectField
								style={{ width: '20.2rem' }}
								label='Perfil do sócio:'
								name='user_type'
								register={register}
								errorMessage={errors.user_type?.message}
							>
								{PartnerProfileOptions.map((option) => (
									<option key={option.value} value={option.value}>
										{option.label}
									</option>
								))}
							</SelectField>
							<DateContainer>
								<InputField
									type="date"
									style={{ width: '16.6rem' }}
									label="Data de fundação:"
									placeholder="00/00/0000"
									name="founding_date"
									register={register}
									errorMessage={errors.founding_date?.message}
								/>
							</DateContainer>
						</RowForm>

						<FileSectionContainer>
							{!file?.name && <Controller
								name="social_contract_file"
								control={control}
								render={({ field }) => (
									<FileField
										accept="application/pdf"
										label="Documento:"
										textContainer="Anexe o contrato social em PDF com tamanho máximo de 3MB"
										style={{ width: '100%', height: '14.3rem' }}
										onChange={handleFileChange(field)}
										errorMessage={errors.social_contract_file?.message}
									/>
								)}
							/>}

							{file?.name && (
								<>
									<ContainerFile>
										<img src={FileArrows} alt="" />
										<FileName>{file?.name}</FileName>
										<ClearButton type={'button'} onClick={clearFile}>
											<IoMdTrash color="var(--primary-blue)" size={24} />
										</ClearButton>
									</ContainerFile>
									{
										errors.social_contract_file?.message && (
											<ErrorMessage>
												<MdErrorOutline /> {errors.social_contract_file?.message}
											</ErrorMessage>
										)
									}
								</>
							)}
							<p>É o documento de constituição da empresa sócia.</p>
						</FileSectionContainer>
					</>
				)}
				<ButtomOptionsContainer>
					<Button
						intent="terciary"
						roundness="lg"
						$outline
						onClick={() => {
							if (editedPartner) {
								RequestClose();
							} else {
								setCurrentStep(currentStep - 1);
								reset();
							}
						}}
					>
						Voltar
					</Button>
					<Button
						onClick={handleSubmit(handleSave)}
						intent="primary"
						roundness="lg"
					>
						Salvar sócio
					</Button>
				</ButtomOptionsContainer>
			</FormContainer>
		);
	};

	const RequestClose = () => {
		setOpen(false);
		reset();
		setCurrentStep(0);
		setTypePartner(null);
		resetUpdatePartner();
	};

	return (
		<>
			<ButtonStyle onClick={() => setOpen(true)} roundness="lg">
				Adicionar novo sócio
			</ButtonStyle>
			<Modal
				headerTitle={
					currentStep === 0
						? 'Cadastro de novo sócio'
						: typePartner === 'Pessoa Física'
							? 'Cadastro de sócio pessoa física'
							: 'Cadastro de sócio PJ'
				}
				onRequestClose={RequestClose}
				isOpen={isOpen}
				position="center"
			>
				<Container>
					{currentStep === 0 && <PartnerTypeContent />}
					{currentStep === 1 && <FormContent />}
				</Container>
			</Modal>
			<Modal
				headerTitle={
					typePartner === 'Pessoa Física'
						? 'Cadastro de sócio pessoa física'
						: 'Cadastro de sócio PJ'
				}
				isOpen={openStatusModal}
				onRequestClose={() => setOpenStatusModal(false)}
			>
				<StatusModal
					message={`Sócio ${editedPartner ? "atualizado" : "salvo" } com sucesso`}
					onClose={() => setOpenStatusModal(false)}
				/>
			</Modal>
			<ErrorModal
				errorMessage={errorMessage}
				resetMessage={() => setErrorMessage(null)}
			/>
		</>
	);
};

export default NewPartnerModal;

const IconContainer = styled.div`
	display: flex;
	padding: 1rem;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
		background: var(--primary-red) ;
		border-radius: 50%;
`

const ErrorBody = styled.div`
	width: 45.5rem;
	display: flex;
	padding: 3.2rem;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 2.4rem;
`

export const ButtonStyle = styled(Button)`
	@media (max-width: 1024px) {
		background: none;
		color: var(--primary-blue);
		border: none;
		padding: 0;
		border-bottom: 1px solid var(--primary-blue);
		border-radius: 0;

		&::hover {
			color: var(--primary-blue);
			background: none;
		}
	}
`;

export const Container = styled.div`
	padding: 3.2rem;
	gap: 3.2rem;
	display: flex;
`;

export const FormContainer = styled.div`
	max-width: 39.2rem;
	display: flex;
	flex-wrap: wrap;
	gap: 2.4rem;

	& > fieldset {
		width: auto;
	}

	& p {
		font-size: 1.2rem;
	}

	@media (max-width: 1024px) {
		& > fieldset {
			width: 100%;
		}
	}
		
	@media (max-width: 1024px) {
			width: 100%;
  }
`;

export const RowForm = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
		
		@media (max-width: 1024px) {
			flex-direction: column;
				gap: 2.4rem;
    }
`;

export const TypePartner = styled.div`
	height: 43.9rem;
	width: 27.6rem;
	border: 2px solid #f0f3f8;

	& img {
		height: 17rem;
	}
`;

export const PartnerInformation = styled.div`
	height: 26.8rem;
	padding: 3.2rem 2.4rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 2.4rem;
	color: var(--dark-gray);

	& h1 {
		font-size: 2rem;
	}

	& p {
		font-size: 1.4rem;
		min-height: 7rem;
	}

	& > div {
		border-top: 2px solid #f0f3f8;
	}
`;

export const ButtomOptionsContainer = styled.div`
	display: flex;
	justify-content: center;
	gap: 1.6rem;
	width: 100%;
`;

const DateContainer = styled.div`
	& input[type='date']::-webkit-calendar-picker-indicator {
		display: none;
		-webkit-appearance: none;
	}
`;

const ContainerFile = styled.div`
	width: 100%;
	height: 5.6rem;
	background-color: #f0f3f8;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1.6rem;
	gap: 1.2rem;

	& img{
		 filter: brightness(0) saturate(100%) invert(61%) sepia(69%) saturate(3072%) hue-rotate(176deg) brightness(100%) contrast(102%);
	}
		
		@media (max-width: 1024px ) {
			width: 100%;
    }
`;

const FileSectionContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 39.2rem;
		
	@media (max-width: 1024px) {
		width: 100%;
	}
`

const FileName = styled.p`
	font-size: 1.6rem;
	color: var(--dark-gray);
	text-overflow: ellipsis;
	overflow: hidden;
	width: 100%;
	height: 5.6rem;
	display: flex;
	align-items: center;
`;

const ClearButton = styled.button`
	background: none;
	border: none;
	color: #007bff;
	cursor: pointer;
	font-size: 0.9rem;
	margin-top: 0.5rem;

	&:hover {
		text-decoration: underline;
	}
`;

const ErrorMessage = styled.span`
	display: flex;
	align-items: center;
	color: red;
	margin-top: 0.5rem;
	width: 36rem;

	svg {
		margin-right: 0.25rem;
	}
`;